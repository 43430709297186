import React from "react";

const Maintenance = () => {
  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#ffffff",
        color: "#333",
        textAlign: "center",
      }}
    >
      <div
        style={{
          maxWidth: "700px",
          padding: "20",
        }}
      >
        <img
          src="https://plasgos.s3.ap-southeast-1.amazonaws.com/logo/logo-plasgos-no-bg.png"
          alt="Maintenance"
        />
        <h1>Sedang Maintenance</h1>
        <p
          style={{
            fontSize: "1.2rem",
            lineHeight: "1.6",
            color: "#555",
          }}
        >
          Kami sedang melakukan pemeliharaan sistem untuk memberikan layanan
          yang lebih baik. Mohon bersabar, dan coba kembali dalam beberapa saat.
          Terima kasih atas pengertiannya!
        </p>
        <div
          style={{
            marginTop: "30px",
            fontSize: "0.9rem",
            color: "#999",
          }}
        >
          © 2025 Plasgos
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
