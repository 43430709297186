import * as device from "react-device-detect";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { saveStorage, getCookie } from "../helpers/storage";

export const getDeviceInfo = async () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  let fingerprint = getCookie("webauth-session");

  if (!fingerprint || fingerprint === "undefined") {
    fingerprint = (await (await FingerprintJS.load()).get()).visitorId;
    saveStorage({ fingerprint });
  }

  return {
    ...device.deviceDetect(),
    timeZone,
    platform: navigator.platform,
    language: navigator.language,
    fingerprint,
  };
};
