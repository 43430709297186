import { Api } from "./api";

export default {
  sessions: (payload) =>
    Api.post("/v3/session", payload, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
        fingerprint: payload.device_info?.fingerprint,
      },
    }),
  lastLogin: (payload) => Api.get(`/v1/log-history/last/${payload.id}`),
  logout: {
    single: (payload) =>
      Api.post(
        "/v3/logout/single",
        {},
        {
          headers: {
            Authorization: `Bearer ${payload.token}`,
          },
        }
      ),
  },
};
