export default {
  AUTHENTICATION: "authentication",
  AUTHENTICATION_SUCCESS: "authentication-success",
  IS_LOADING_AUTHENTICATION: "set-is-loading-authentication",

  LAST_LOGIN: "last-login",
  LAST_LOGIN_SUCCESS: "last-login-success",
  IS_LOADING_LAST_LOGIN: "is-loading-last-login",

  LOGOUT: "logout",
  LOGOUT_SUCCESS: "logout-success",
  IS_LOADING_LOGOUT: "is-loading-logout",

  SET_REFRESH_LOGIN: "set-refresh-login",
  SET_REFRESH_TOKEN_LOGIN: "set-refresh-token-login",
};
