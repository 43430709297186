import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ReactLoading from "react-loading";
import { registerLocale } from "react-datepicker";
import id from "date-fns/locale/id";
import ModalLoading from "./components/loading/ModalLoading";
import Maintenance from "./views/pages/maintenance";
import { useSelector, useDispatch } from "react-redux";
import "./scss/style.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "moment/locale/id";
import "animate.css";

import { getDeviceInfo } from "./lib/device";
import { getAuthentication } from "./components/login/reducer";
import { getCookie } from "./helpers/storage";
import { apiUrl, baseUrl, sellerBaseUrl } from "./services/api";

registerLocale("id", id);

const componentLoad = (
  <div className="c-app c-default-layout d-flex align-items-center justify-content-center">
    <div className="pt-3 text-center">
      <ReactLoading type="bubbles" color="#fa541c" />
    </div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const PrintInvoice = React.lazy(() => import("./views/selling/pages/invoice"));
const PrintLabel = React.lazy(() => import("./views/selling/pages/label"));
const ProcessPackagePrintReceipt = React.lazy(() =>
  import("./views/package/print/receipt")
);
const CustomPage = React.lazy(() => import("./views/landing-page"));

const App = () => {
  const dispatch = useDispatch();
  const { logged_in, user, loading } = useSelector((state) => state.login);
  const { isLoadingGet } = useSelector((state) => state.warehouse);
  const { process, history } = useSelector((state) => state.package);
  const is_loading =
    isLoadingGet || process.loading || history.loading || loading;
  const [isOnline, setIsOnline] = useState(true);

  const getData = async () => {
    const deviceInfo = await getDeviceInfo();
    await dispatch(
      getAuthentication({
        token: getCookie("ua_t"),
        device_info: deviceInfo,
      })
    );
  };
  useEffect(() => {
    if (!logged_in && !user.id && !getCookie("ua_t")) {
      window.location.href = `${baseUrl}/login?p=${encodeURIComponent(
        sellerBaseUrl
      )}`;
    }
    return () => {};
  }, [logged_in, user]);
  useEffect(() => {
    getData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  useEffect(() => {
    const checkServer = async () => {
      try {
        const response = await fetch(`${apiUrl}/status`);
        const data = await response.json();
        setIsOnline(data.online);
      } catch (error) {
        setIsOnline(false);
      }
    };
    checkServer();
    const interval = setInterval(checkServer, 300000); // Cek setiap 5 menit

    return () => clearInterval(interval);
  }, []);
  if (!isOnline) {
    return <Maintenance />;
  }
  if (!logged_in) {
    return null;
  }
  return (
    <div>
      <BrowserRouter>
        <React.Suspense fallback={componentLoad}>
          <Switch>
            {/* Abaikan route /crm */}
            <Route path="/crm">
              <Redirect to="/crm/" />
            </Route>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              path="/print/invoices"
              name="Cetak Resi Pengiriman"
              render={(props) => <PrintInvoice {...props} />}
            />
            <Route
              exact
              path="/print/labels"
              name="Cetak Label"
              render={(props) => <PrintLabel {...props} />}
            />
            <Route
              exact
              path="/packages/print/receipt"
              name="Cetak Resi"
              render={(props) => <ProcessPackagePrintReceipt {...props} />}
            />
            <Route
              exact
              path="/page/custom/:id"
              name="Kustom Produk"
              render={(props) => <CustomPage {...props} />}
            />

            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
      <ModalLoading show={is_loading} />
    </div>
  );
};

export default App;
