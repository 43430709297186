import Cookies from "js-cookie";
import config from "../config";

const domain =
  process.env.NODE_ENV === "development"
    ? config.development.DOMAIN_COOKIE
    : config.production.DOMAIN_COOKIE;

export const saveStorage = ({
  user,
  accessToken,
  refreshToken,
  fingerprint,
}) => {
  if (accessToken) {
    Cookies.set("ua_t", accessToken, {
      domain,
      expires: 7,
      secure: true,
      sameSite: "None",
    });
    localStorage.setItem("key_x-log", accessToken);
    sessionStorage.setItem("key_x-log", accessToken);
  }
  if (refreshToken) {
    Cookies.set("ur_t", refreshToken, {
      domain,
      expires: 7,
      secure: true,
      sameSite: "None",
    });
  }
  if (user) {
    sessionStorage.setItem(
      "lstlgn",
      JSON.stringify({
        nm: user.name,
        pic: user.avatar_img,
      })
    );
    localStorage.setItem(
      "lstlgn",
      JSON.stringify({
        nm: user.name,
        pic: user.avatar_img,
      })
    );
  }
  if (fingerprint) {
    Cookies.set("webauth-session", fingerprint, {
      domain,
      expires: 7,
      secure: true,
      sameSite: "None",
    });
  }
};
export const removeStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
  Cookies.remove("ur_t");
  Cookies.remove("ua_t");
};
export const getCookie = (name) => {
  const value = Cookies.get(name);
  return value;
};
