import { put, call, takeLatest } from "redux-saga/effects";

import Api from "../../services";
import types from "./constant";
import * as actions from "./reducer";
import { baseUrl, sellerBaseUrl } from "../../services/api";
import { saveStorage, removeStorage } from "../../helpers/storage";

function* watchAuthenticatonUser(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingAuthentication(true));
  try {
    const response = yield call(Api.authentication.sessions, payload);
    const { data } = response;
    if (data.success) {
      if (data.data.store) {
        let result = {
          user: data.data,
          token: data.data.token,
          logged_in: true,
        };
        yield saveStorage({ user: result.user, accessToken: result.token });
        yield put(actions.getAuthenticationSuccess(result));
      } else {
        window.location.href = `${baseUrl}/open-shop?p=${encodeURIComponent(
          sellerBaseUrl
        )}`;
      }
    }
  } catch (error) {
    const { data } = error.response;
    if (data?.error_code?.toLowerCase() === "unauthorized") {
      yield put(actions.getLogout(payload));
    }
  } finally {
    yield put(actions.setIsLoadingAuthentication(false));
  }
}
function* watchLastLogin(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingLastLogin(true));
  yield put(actions.getLastLoginSuccess({}));
  try {
    const response = yield call(Api.authentication.lastLogin, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getLastLoginSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingLastLogin(false));
    yield put(actions.getLastLoginSuccess({}));
  } finally {
    yield put(actions.setIsLoadingLastLogin(false));
  }
}

function* watchLogout(values) {
  const { payload } = values;
  yield put(actions.setIsLoadingLogout(true));
  try {
    yield call(Api.authentication.logout.single, payload);
    yield removeStorage();
    yield put(actions.getLogoutSuccess());
  } catch (error) {
    const { data } = error.response;
    if (
      error.response.status === 500 ||
      data?.error_code?.toLowerCase() === "unauthorized"
    ) {
      yield removeStorage();
      yield put(actions.getLogoutSuccess());
    }
    yield put(actions.setIsLoadingLogout(false));
  } finally {
    yield put(actions.setIsLoadingLogout(false));
  }
}
const loginSagas = [
  takeLatest(types.AUTHENTICATION, watchAuthenticatonUser),
  takeLatest(types.LOGOUT, watchLogout),
  takeLatest(types.LAST_LOGIN, watchLastLogin),
];

export default loginSagas;
