import types from "./constant";

const initialState = {
  user: {},
  token: "",
  logged_in: false,
  loading: false,
  refresh: false,
  last_login: {},
  isLoadingLastLogin: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REFRESH_LOGIN:
      return {
        ...state,
        refresh: action.payload,
      };
    case types.SET_REFRESH_TOKEN_LOGIN:
      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload,
        },
        token: action.payload,
      };

    case types.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        logged_in: action.payload.logged_in,
      };
    case types.IS_LOADING_AUTHENTICATION:
      return { ...state, loading: action.payload };
    case types.LAST_LOGIN_SUCCESS:
      return { ...state, last_login: action.payload };
    case types.IS_LOADING_LAST_LOGIN:
      return { ...state, isLoadingLastLogin: action.payload };
    case types.LOGOUT_SUCCESS:
      return { ...state, ...initialState };
    case types.IS_LOADING_LOGOUT:
      return { ...state, isLoadingLogout: action.payload };
    default:
      return state;
  }
};

export const getLastLogin = (payload) => ({
  type: types.LAST_LOGIN,
  payload,
});
export const getLastLoginSuccess = (payload) => ({
  type: types.LAST_LOGIN_SUCCESS,
  payload,
});
export const setIsLoadingLastLogin = (payload) => ({
  type: types.IS_LOADING_LAST_LOGIN,
  payload,
});

export const getAuthentication = (payload) => ({
  type: types.AUTHENTICATION,
  payload,
});
export const getAuthenticationSuccess = (payload) => ({
  type: types.AUTHENTICATION_SUCCESS,
  payload,
});
export const setIsLoadingAuthentication = (payload) => ({
  type: types.IS_LOADING_AUTHENTICATION,
  payload,
});

//logout
export const getLogout = (payload) => ({
  type: types.LOGOUT,
  payload,
});
export const getLogoutSuccess = (payload) => ({
  type: types.LOGOUT_SUCCESS,
  payload,
});
export const setIsLoadingLogout = (payload) => ({
  type: types.IS_LOADING_LOGOUT,
  payload,
});

export const setRefreshLogin = (payload) => ({
  type: types.SET_REFRESH_LOGIN,
  payload,
});
export const setRefreshToken = (payload) => ({
  type: types.SET_REFRESH_TOKEN_LOGIN,
  payload,
});
